import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';
import {ReplaySubject} from 'rxjs';
import {LandingContent} from './landing-content';

@Component({
    selector: 'landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent implements OnInit {
    public content$ = new ReplaySubject<LandingContent>(1);
    public panelOpenState:any;
    constructor(
        public settings: Settings,
    ) {}
        public faq = []
    ngOnInit() {
        this.settings.all$().subscribe(() => {
            this.content$.next(this.settings.getJson('landing.appearance'));
        });
        this.faq=[{
            'panelOpenState':false,
            "question":"What is Wealthflix?",
            "answer":["Wealthflix is  streaming service that offers a wide variety of professional, financial courses and audiobooks on thousands of connected devices.",
        "Stream as much as you want anytime without ad breaks for a low monthly price. There’s always new updates and courses to watch."]
        },
        { 'panelOpenState':false,
            "question":"How much does Wealthflix cost?",
            "answer":["Watch Wealthflix on any smart device and computer for the low price of $19.99 a month. No hidden costs."]
        },
        { 'panelOpenState':false,
            "question":"Where can I watch?",
            "answer":["You can stream Wealthflix anytime, anywhere. Simply just sign in on your device."]
        },
        { 'panelOpenState':false,
            "question":"What can I watch?",
            "answer":["Wealthflix has a wide variety of hand picked courses by the best and leading professionals. You can also enjoy our collection of auidiobooks."]
        },
        { 'panelOpenState':false,
            "question":"Why pay with bitcoin?",
            "answer":["Wealthflix is a contractless service and we believe in the security of our users. Bitcoin provides such security by not having to provide your personal or credit card information."]
        }
    
    ]
    }

    public isInlineIcon(url: string): boolean {
        return !url.includes('.') && !url.includes('/');
    }
  
}
