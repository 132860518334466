<div class="current-rating d-flex flex-row justify-content-center align-items-center" *ngIf="item.rating">
    <span class="small material-icons bg-yellow">star</span>
    <span class="small">{{item.rating}} / 10</span>
</div>

<ng-container *ngIf="showRateButton">
    <div class="separator" *ngIf="item.rating"></div>

    <button mat-button class="user-rating rate-button" *ngIf="userRating$ | async as userRating; else rateThis" (authClick)="openRatingOverlay($event)">
        <mat-icon class="small">start</mat-icon>
        <span class="text small">
        <span trans class="small">Your Score</span>:
        <span class="score small"> {{userRating.score}}</span>
    </span>
    </button>

    <ng-template #rateThis>
        <button mat-button class="rate-this rate-button" (authClick)="openRatingOverlay($event)">
            <span class="small material-icons">star_border</span>
            <span class="small" trans>Rate This</span>
        </button>
    </ng-template>
</ng-container>