<slider [list]="sliderList">
    <media-item-header [transparent]="true"></media-item-header>
</slider>

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<section class="lists" style=" margin-right: 0%;margin-top: -12%;z-index: 1030;margin-left:0%;">
    <ng-container *ngFor="let list of lists$ | async; index as index">
        <div class="list">
            <div class="row m-0 p-0">
            <div class="col-12  m-0 p-0 w-100" >
                <h2 class="d-flex font-weight-bold h2-title homeList" >
                    <a [routerLink]="['/lists', list.id]">{{list.name}}
                     
                    </a>
                    <span class="small fs-3 mx-3 d-flex align-items-center justify-content-center" style="margin-left: 25px !important;"> <a  [routerLink]="['/lists', list.id]"><span class="explore">Explore All</span> <i class="material-icons exploreIcon">chevron_right</i></a></span>
                
                 </h2>
                <!-- <p *ngIf="list.description">{{list.description}}</p> -->

                <media-grid [mode]="list.style === 'landscape-grid' ? 'landscape' : 'portrait'" [items]="list.items" class="full-width" [showPlayButton]="settings.get('homepage.show_play_buttons')"></media-grid>
     
            </div>  
            </div>
        </div>

        <ad-host slot="ads.homepage" *ngIf="index === 0" class="homepage-ad-host"></ad-host>
    </ng-container>
    <no-results-message *ngIf="currentUser.hasPermission('admin') && !(lists$ | async).length" svgImage="task-list.svg">
        <span primary-text trans>No homepage content yet.</span>
        <span secondary-text trans>Assign some lists to the homepage from <a routerLink="/admin/appearance/homepage" [queryParams]="{panel: 'homepage'}">appearance editor</a>.</span>
    </no-results-message>
</section>

<footer></footer>
