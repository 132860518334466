import { element } from 'protractor';
import { List } from './../../../models/list';
import { style } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, HostBinding,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {Title} from '../../../models/title';
import {Person} from '../../../models/person';
import {TitleUrlsService} from '../../titles/title-urls.service';
import {MEDIA_TYPE} from '../../media-type';
import {PlayerOverlayService} from '../../player/player-overlay.service';
import {CarouselModule} from 'primeng/carousel';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../homepage/dialog/dialog.component';
import { Router } from '@angular/router';
import { UserLibraryService } from '../../user-library.service';
import { BehaviorSubject } from 'rxjs';
declare var  $ :any;
@Component({
    selector: 'media-grid',
    templateUrl: './media-grid.component.html',
    styleUrls: [
        './media-grid.component.scss',
        './portrait-media-grid.component.scss',
        './landscape-media-grid.component.scss'
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaGridComponent implements OnInit {
    @Input() items: (Title|Person)[] = [];
    @Input() grid: any =false;
    @Input() mode: 'landscape'|'portrait' = 'portrait';
    @Input() showPlayButton = false;
    @Output() actionClick = new EventEmitter();
    @HostBinding('class') get modeClass() {
        return `${this.mode}-media-grid`;
    }
    public responsiveOptions:any;
    public trackByFn = (i: number, item: Title|Person) => item.id;
    public loading$ = new BehaviorSubject(false);
    public itemInWatchlist$ = new BehaviorSubject(false);
    constructor(
      
        public urls: TitleUrlsService,
        private playerOverlay: PlayerOverlayService,
        public dialog: MatDialog,
        public router:Router,
        public userLibrary: UserLibraryService,
        private cdr: ChangeDetectorRef
    ) {
        
    }
    
    public customOptions:OwlOptions;
        ngOnInit(){
          // if(this.grid){
          //   $('media-grid').attr("style","display: grid !important;")
          // }
            this.mode='landscape';
            this.customOptions= {
                loop: true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                stagePadding: 0,
              //  margin:100,
               
                items:6,
                dots: false,
                // center: true,
                // autoWidth:true,
                navSpeed: 700,
                
                navText: [ '<i class="material-icons">chevron_left</i>', '<i class="material-icons">chevron_right</i>' ],
                responsive: {
                  0: {
                    items: 1
                  },
                  400: {
                    items: 2
                  },
                  740: {
                    items: 3
                  },
                  940: {
                    items: 4
                  },
                  1300: {
                    items: 6
                  }
                },
                nav: true,
            
              }
                   
              this.items.forEach(element => {
                if( this.userLibrary.watchlist && this.userLibrary.watchlist.items
                 .findIndex(i => i.id === element?.id && i.type === element?.type) > -1){
                   element["watchList"]=true;
                 }
               });
              $(document).ready(()=>{
                $('.owl-item,figure').on("mouseenter",function(event){
                 
// console.log("Mouser Enter");

// $('.owl-prev').addClass('left');

// $('.owl-next').addClass('right');

$(this).closest('.owl-carousel').children('.owl-nav').addClass('opacity-0');
$(this).closest('.owl-carousel').children('.owl-nav').removeClass('opacity-1');
// $(this).find('figcaption').removeClass("d-none")
// $(this).find('figcaption').addClass("d-block")
// $('.owl-stage-outer').removeClass('h-300');
// $('.owl-stage-outer').addClass('h-300');
                });
                $('.owl-item').on("mouseleave",function(){
                  $(this).closest('.owl-carousel').children('.owl-nav').addClass('opacity-1');
                    $(this).closest('.owl-carousel').children('.owl-nav').removeClass('opacity-0');
// $(this).find('figcaption').addClass("d-none")
// $(this).find('figcaption').removeClass("d-block")
                    // console.log("mouseleave");
                    // $('.owl-prev').removeClass('left');
                    // $('.owl-next').removeClass('right');
                    
                    // $('.owl-stage-outer').removeClass('h-300');
                    // $('.owl-stage-outer').addClass('overflow-x-clip');
                                    });
              })
        }
        
        getData(data: SlidesOutputData) {
         this.customOptions['singleItem']= true;
          }
          playVideo(title) {
            this.playerOverlay.open(title.videos[0], title);
        }
    public isPerson(item: Title|Person) {
        return item.type === MEDIA_TYPE.PERSON;
    }

    public async addToWatchlist(item) {
      this.loading$.next(true);
    await  this.userLibrary.addToWatchlist(item).then(() => {
          this.itemInWatchlist$.next(true);
   
          this.loading$.next(false);
          item["watchList"]=true;
          this.cdr.detectChanges();
      });
  }
    OpenDialog(item,list){
      // $('body').attr("style","overflow-x:visible !important");
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '800px',
            maxHeight: '700px',
            data:{ "item":item,"list":list}
          });
      
          dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
            // this.animal = result;
          });
          this.router.events
 .subscribe(() => {
   dialogRef.close();
 });
    }

    public isReleased(release_date) {
      return new Date(release_date) < new Date();
  }
 async ngOnChanges() {
 await   this.items.forEach(element => {
     if( this.userLibrary.watchlist && this.userLibrary.watchlist.items
      .findIndex(i => i.id === element?.id && i.type === element?.type) > -1){
        element["watchList"]=true;
      }
    });
}

  public async removeFromWatchlist(item) {
    this.loading$.next(true);
 
    await this.userLibrary.removeFromWatchlist(item).then(() => {
        this.itemInWatchlist$.next(false);
        item["watchList"]=false;
        this.cdr.detectChanges();
        this.loading$.next(false);
    });
  }
   }
