
 
   <!-- <ng-container *ngIf="pagination$ | async as pagination"> -->

    <slider [list]="headerList" >
        <media-item-header [transparent]="true">
        
        </media-item-header>
        <div class="d-flex flex-row text-white" style="
        left: 2%;
        position: absolute;
        top: 10%;
    ">
            <span class="fs-1">
                {{title}} 
            </span>
            <span class="fs-1 mx-3">
                <button mat-button [matMenuTriggerFor]="menu" class="border">Genres <i class="material-icons">expand_more</i></button>
                <mat-menu #menu="matMenu" >
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-12"  *ngFor="let genre of genres">  <button (click)="navigate(genre)" mat-menu-item matTooltip="{{genre}}">{{genre}}</button>
                 </div>
               
                </div>
                </mat-menu>
         </span>
        </div>
    </slider>

<!-- </ng-container> -->

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<!-- <mat-sidenav-container class="container main-container"> -->
    <!-- <mat-sidenav class="sidebar" [mode]="breakpoints.isMobile$.value ? 'over' : 'side'" [opened]="!breakpoints.isMobile$.value" [fixedInViewport]="breakpoints.isMobile$.value" #sidenav>
        <form [formGroup]="form">
            <div class="filter-container input-container">
                <label class="filter-header" for="type" trans>Title Type</label>
                <select name="type" id="type" formControlName="type">
                    <option [ngValue]="null" trans>All Types</option>
                    <option value="movie" trans>Movie</option>
                    <option value="series" trans>TV Series</option>
                </select>
            </div>

            <div class="filter-container" *ngIf="settings.get('browse.show_streamable_filter')">
                <mat-slide-toggle formControlName="onlyStreamable" trans>Only Streamable</mat-slide-toggle>
            </div>

            <div class="genres-filter filter-container">
                <div class="filter-header" trans>With selected genres</div>
                <mat-selection-list formControlName="genre">
                    <mat-list-option [value]="genre" *ngFor="let genre of genres" checkboxPosition="before" trans>{{genre}}</mat-list-option>
                </mat-selection-list>
            </div>

            <div class="slider-filter filter-container">
                <div class="filter-header" trans>Released In</div>
                <range-slider [min]="yearSliderMin" [max]="yearSliderMax" [step]="1" formControlName="released"></range-slider>
            </div>

            <div class="slider-filter filter-container">
                <div class="filter-header" trans>User Score</div>
                <range-slider [min]="1" [max]="10" [step]="0.1" [float]="true" formControlName="score"></range-slider>
            </div>

            <div class="slider-filter filter-container">
                <div class="filter-header" trans>Runtime</div>
                <range-slider [min]="1" [max]="255" [step]="1" affix="min" formControlName="runtime"></range-slider>
            </div>

            <div class="filter-container input-container">
                <div class="filter-header" trans>Country</div>
                <select id="country" formControlName="country">
                    <option [ngValue]="null" trans>All Countries</option>
                    <option [value]="option.code" *ngFor="let option of countries" trans>{{option.name}}</option>
                </select>
            </div>

            <div class="filter-container input-container">
                <div class="filter-header" trans>Language</div>
                <select id="language" formControlName="language">
                    <option [ngValue]="null" trans>All Languages</option>
                    <option [value]="option.code" *ngFor="let option of languages" trans>{{option.name}}</option>
                </select>
            </div>

            <div class="filter-container">
                <div class="filter-header" trans>Age Ratings</div>
                <mat-button-toggle-group formControlName="certification" aria-label="Certification">
                    <mat-button-toggle [value]="certification" *ngFor="let certification of certifications">{{certification}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <button class="reset-filters-btn" mat-stroked-button (click)="clearAllFilters()" [disabled]="!(anyFilterActive$ | async)" trans>
                <mat-icon svgIcon="refresh"></mat-icon>
                <span trans>Reset Filters</span>
            </button>
        </form>
    </mat-sidenav> -->
    <!-- <mat-sidenav-content> -->
        <div class="row m-0 p-0">
            <!-- <div class="header">
                <h1 trans>Browse</h1>
                <div class="applied-filters">
                    <button class="toggle-filters-button" mat-icon-button (click)="sidenav.toggle()" *ngIf="breakpoints.isMobile$.value">
                        <mat-icon svgIcon="tune"></mat-icon>
                    </button>
                    <button mat-stroked-button class="clear-all-filters-button" (click)="clearAllFilters()" *ngIf="anyFilterActive$ | async">
                        <span class="text" trans>Reset Filters</span>
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </div>
                <sort-dropdown [formControl]="form.get('order')" [sortOptions]="sortOptions"></sort-dropdown>
            </div> -->
            <section class="lists" style=" margin-right: 0%;margin-top: -12%;z-index: 1030;margin-left:0%;">

            <ng-container *ngIf="pagination$ | async as pagination" class="mt-5">
 <div class="list">
            <div class="row m-0 p-0">
            <div class="col-12  m-0 p-0 w-100 " >
                <h2 class="d-flex font-weight-bold  h2-title" style="margin:0% 2%">
                    <a [routerLink]="['']">title
                     
                    </a>
                    <span class="small fs-3 mx-3 d-flex align-items-center justify-content-center"> <a  [routerLink]="['']"><span class="explore">Explore All</span> <i class="material-icons exploreIcon">chevron_right</i></a></span>
                
                 </h2>
                <media-grid [items]="pagination?.data"></media-grid>
                </div>
                </div>
                </div>
                <no-results-message *ngIf="pagination?.data?.length === 0">
                    <span primary-text trans>Nothing To Display.</span>
                    <span secondary-text trans>Seems like no titles match specified filters.</span>
                </no-results-message>
            </ng-container>
            </section>
        </div>
    <!-- </mat-sidenav-content> -->
<!-- </mat-sidenav-container> -->

<back-to-top-button></back-to-top-button>

<footer></footer>
<loading-indicator [isVisible]="loading$ | async" class="overlay"></loading-indicator>
