import { filter } from 'rxjs/operators';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { PlayerOverlayService } from '../../player/player-overlay.service';
import { BehaviorSubject } from 'rxjs';
import { UserLibraryService } from '../../user-library.service';

@Component({
    // selector: 'dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
  })
  export class DialogComponent implements OnInit {
    public counter:number = 6;
    public loading$ = new BehaviorSubject(false);
    public itemInWatchlist$ = new BehaviorSubject(false);
    constructor(
      public dialogRef: MatDialogRef<DialogComponent>,
      private playerOverlay: PlayerOverlayService,

      @Inject(MAT_DIALOG_DATA) public data: { value: any },
      public urls: TitleUrlsService,
      public userLibrary: UserLibraryService
      ) {

      }
 
    
    ngOnInit(){
     this.data['list']= this.data['list'].filter (x=>x.id!=this.data['item']['id']);
      if(this.data['list'].length>6){
        this.counter=6
      }else{
        this.counter=this.data['list'].length
      }
      this.itemInWatchlist$.next(
        this.userLibrary.watchlist && this.userLibrary.watchlist.items
            .findIndex(i => i.id === this.data['item']?.id && i.type === this.data['item']?.type) > -1
    );
    this.data['list'].forEach(element => {
      if( this.userLibrary.watchlist && this.userLibrary.watchlist.items
       .findIndex(i => i.id === element?.id && i.type === element?.type) > -1){
         element["watchList"]=true;
       }
     });
    }
    public isReleased(release_date) {
      return new Date(release_date) < new Date();
  }
  ngOnChanges() {
    this.itemInWatchlist$.next(
        this.userLibrary.watchlist && this.userLibrary.watchlist.items
            .findIndex(i => i.id === this.data['item']?.id && i.type === this.data['item']?.type) > -1
    );
}
  public addToWatchlist() {
    this.loading$.next(true);
    this.userLibrary.addToWatchlist(this.data['item']).then(() => {
        this.itemInWatchlist$.next(true);
        this.loading$.next(false);
    });
}
public removeFromWatchlist() {
  this.loading$.next(true);
  this.userLibrary.removeFromWatchlist(this.data['item']).then(() => {
      this.itemInWatchlist$.next(false);
      this.loading$.next(false);
  });
}
    onNoClick(): void {
      this.dialogRef.close();
    }
    toggleExpansion(){
    if(this.counter==this.data['list'].length){
      this.counter=6
    }else{
      this.counter=this.data['list'].length;
    }
  }
     public async AddToWatchlist(item) {
      this.loading$.next(true);
    await  this.userLibrary.addToWatchlist(item).then(() => {
          this.itemInWatchlist$.next(true);
   
          this.loading$.next(false);
          item["watchList"]=true;
      });
  }
  public async RemoveFromWatchlist(item) {
    this.loading$.next(true);
 
    await this.userLibrary.removeFromWatchlist(item).then(() => {
        this.itemInWatchlist$.next(false);
        item["watchList"]=false;
        this.loading$.next(false);
    });
  }
   playVideo(title) {
    this.playerOverlay.open(title.videos[0], title);
}
  }