<ng-container *ngIf="content$ | async as content">
    <header>
        <material-navbar class="login-button-accent" menuPosition="landing" [mainToggleButton]="false" [transparent]="true" [hideRegisterButton]="true" [MainMenu]="true"></material-navbar>
        <div class="content container">
            <h1>{{content.headerTitle}}</h1>
            <p>{{content.headerSubtitle}}</p>
            <h3>Ready to watch? Enter your email to create or restart your membership.</h3>
            <div class="col-md-7 mx-auto col-sm-12 col-12">
              <div class="d-md-flex d-sm-block d-block flex-row">
                <mat-form-field class="bg-white p-2 col-md-8 col-sm-12 mt-3  col-12" appearance="none">
                    <mat-label class="h6 text-muted p-2 medium-font">Email address</mat-label>
                    <input matInput class="p-2 text-muted " >
                
                  </mat-form-field>
                  <button mat-suffix class="btn btn-primary col-md-4 mt-3 medium-font">Get started  <i class="material-icons">chevron_right</i></button>
              </div>
            </div>
        
        </div>
        <div class="background" [style.background-image]="'url(' + content.headerImage + ')'"></div>
        <div class="overlay" [style.background-color]="content.headerOverlayColor"></div>
        <div class="gradient-overlay"></div>
    </header>

    <!-- <div class="container">
        <ad-host slot="ads.landing.top"></ad-host>
        <ul class="inline-features">
            <li class="inline-feature" *ngFor="let primaryFeature of content.primaryFeatures">
                <image-or-icon [src]="primaryFeature.image"></image-or-icon>
                <h3>{{primaryFeature.title}}</h3>
                <p>{{primaryFeature.subtitle}}</p>
            </li>
        </ul>
    </div> -->

    <div class="spacer"></div>

    <ul class="big-features">
        <ng-container *ngFor="let secondaryFeature of content.secondaryFeatures; first as first; last as last; even as even">
            <li class="big-feature container"  [class.first-secondary-feature]="first" [class.reverse]="even">
                <img [src]="settings.getBaseUrl(false) + secondaryFeature.image + '?v2'" alt="">
                <section class="info">
                    <!-- <small>{{secondaryFeature.subtitle}}</small> -->
                    <h2>{{secondaryFeature.title}}</h2>
                    <!-- <div class="feature-border"></div> -->
                    <p class="medium-font">{{secondaryFeature.description}}</p>
                </section>
            </li>

            <div class="spacer" *ngIf="!last"></div>
        </ng-container>
    </ul>
    <div class="row m-0 p-0 border-top">
        <div class="container ">
            <h2 class="FaQ-title text-center my-5">Frequently Asked Questions</h2>
            <div class="col-md-8 col-sm-10 col-12 mx-auto p-3 mt-4 ">
              <div class="row p-3">
                <mat-accordion >
                    
                    <mat-expansion-panel class="bg-secondary p-3 mt-4 " *ngFor="let q of faq;let i=index" (opened)="q.panelOpenState= true" (closed)="q.panelOpenState= false"hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3 class="medium-font"> {{q.question}}</h3>
                        </mat-panel-title>
                        <mat-panel-description>
                            <mat-icon class="medium-font" *ngIf="!q.panelOpenState">add</mat-icon>
                            <mat-icon class="medium-font" *ngIf="q.panelOpenState">close</mat-icon>
                          </mat-panel-description> 
                      </mat-expansion-panel-header>
                  
                      <div class="border-top">
                      

                         
                        <p *ngFor="let a of q.answer" class="medium-font mt-3">
                             {{a}}
                        </p>   
                     
                      </div>
                  
                    </mat-expansion-panel>

                    </mat-accordion>
                 
              </div>
               
            </div>
           
        </div>
    </div>

    <div class="row m-0 p-0">
        <div class="container text-center mt-5">
            <h3>Ready to watch? Enter your email to create or restart your membership.</h3>
            <div class="col-md-7 mx-auto col-sm-12 col-12">
                <div class="d-md-flex d-sm-block d-block flex-row">
                  <mat-form-field class="bg-white p-2 col-md-8 col-sm-12 mt-3  col-12" appearance="none">
                      <mat-label class="h6 text-muted p-2 medium-font">Email address</mat-label>
                      <input matInput class="p-2 text-muted " >
                  
                    </mat-form-field>
                    <button mat-suffix class="btn btn-primary col-md-4 mt-3 medium-font">Get started  <i class="material-icons">chevron_right</i></button>
                </div>
              </div>
           
        </div>
    </div>
    <footer></footer>
</ng-container>
