<media-item-header></media-item-header>

<div class="container">
    <h1>
        <span trans>Search results for:</span>
        &nbsp;
        <span class="query">"{{query$ | async}}"</span>
    </h1>

    <div class="type-container" *ngIf="(movies$ | async)" >
        <div class="title" trans>Movies</div>
        <media-grid [items]="movies$ | async" [grid]=true></media-grid>
    </div>

    <!-- <div class="type-container" *ngIf="(series$ | async)">
        <div class="title" trans>Series</div>
        <media-grid [items]="series$ | async" [grid]=true></media-grid>
    </div> -->

    <div class="type-container" *ngIf="(people$ | async)">
        <div class="title" trans>People</div>
        <media-grid [items]="people$ | async" [grid]=true></media-grid>
    </div>
</div>

<footer></footer>