<div class="footer-content">
    <section class="container-footer container pb-0">
        <!-- <div class="col about-col">
            <div class="title" trans>About</div>
            <div class="content">{{siteDescription()}}</div>
        </div> -->
        <div class="col-12">
            <p><a href="#" class="link-light text-muted text-decoration-none fs-2">Questions? Contact us.</a></p>
        </div>
        <custom-menu class="col" position="footer-1" [showTitle]="true"></custom-menu>
        <custom-menu class="col" position="footer-2" [showTitle]="true"></custom-menu>
        <custom-menu class="col" position="footer-3" [showTitle]="true"></custom-menu>
        <custom-menu class="col" position="footer-4" [showTitle]="true"></custom-menu>
      
    </section>
    <section class="container  pb-5">
        <div class="footer-bar">
            <span trans class="fs-3 text-muted">Wealthflix</span>
        </div>
    </section>
</div>

