
<div class="row m-0 p-0">
    <div class="col-12 text-right pt-2">
        <button class="btn btn-sm text-right material-icons close-btn" (click)="onNoClick()">close</button>
    </div>
    <div class="col-12 py-5">
        <p class="fs-4">
            {{data['desc']}}
        </p>
    </div>
</div>