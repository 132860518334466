import {Injectable} from '@angular/core';
import {Video} from '../../../models/video';
import {LazyLoaderService} from '@common/core/utils/lazy-loader.service';
import {Settings} from '@common/core/config/settings.service';
import {PlayerQualityVariantOptions} from './shaka-strategy.service';
import {Subject} from 'rxjs';

declare const Plyr: any;

@Injectable({
    providedIn: 'root'
})
export class PlyrStrategyService {
    public player: any;
    public playbackEnded$ = new Subject();
    video: Video;
    constructor(
        private lazyLoader: LazyLoaderService,
        private settings: Settings,
    ) {}

    public loadSource(videoEl: HTMLVideoElement, video: Video, variantOptions?: PlayerQualityVariantOptions): Promise<void> {
        return this.initPlayer(videoEl, video, variantOptions).then(() => {
            if (video && video.type !== 'stream') {
                this.player.source = this.buildSource(video);
            }
        });
    }

    public loadAssets(): Promise<any> {
        return Promise.all([
            this.lazyLoader.loadAsset('js/plyr.min.js', {type: 'js'}),
            this.lazyLoader.loadAsset('css/plyr.css', {type: 'css'}),
        ]);
    }

    private initPlayer(videoEl: HTMLVideoElement, video: Video, variantOptions?: PlayerQualityVariantOptions): Promise<void> {
        this.video = video;
        console.log('video', this.video);
        // const cookieName = 'ply_time_' + this.video.id;
        const playbackTime = this.readCookie('ply_time_' + this.video.id);
        if (this.player) {
            this.player.on('ready', event => {
                const player = event.detail.plyr;
                player.currentTime = parseFloat(playbackTime);
            });
            return Promise.resolve();
        } else {
            return this.loadAssets().then(() => {
                const plyrOptions = {
                    autoplay: true,
                    quality: {},
                    // plyr doesn't allow "auto" quality for whatever reason,
                    // need to use zero for auto quality and translate it
                    i18n: {qualityLabel: {0: 'Auto'}}
                };
                if (variantOptions && variantOptions.variants.length) {
                    plyrOptions.quality = {
                        default: variantOptions.variants[0].quality,
                        forced: true,
                        onChange: variantOptions.onChange,
                        options: variantOptions.variants.map(qv => qv.quality)
                    };
                }
                this.player = new Plyr(videoEl, plyrOptions);
                this.player.on('ready', (event) => {
                    const player = event.detail.plyr;
                    player.currentTime = parseFloat(playbackTime);
                });
                this.player.on('loadeddata', (event) => {
                    const player = event.detail.plyr;
                    player.currentTime = parseFloat(playbackTime);
                });
                this.player.on('statechange', event => {
                    const player = event.detail.plyr;
                    this.onPlayerStateChange(player.currentTime);
                });
                this.player.on('pause', event => {
                    const player = event.detail.plyr;
                    this.onPlayerStateChange(player.currentTime);
                });
                this.player.on('ended', () => {
                   this.eraseCookie(`ply_time_${this.video.id}`);
                   this.playbackEnded$.next();
                });
            });
        }
    }

    onPlayerReady() {
        this.player.currentTime = this.readCookie(`ply_time_${this.video.id}`);  // On ready get ccokies  and start vide from that.
    }

    onPlayerStateChange(currentTime) {
        console.log('state change', `ply_time_${this.video.id}`);
        console.log('currentTime', currentTime);
        this.createCookie(`ply_time_${this.video.id}`, currentTime, 30);  // Stats like buffer, Pause and play store time in Cookes
    }

    /*
         * Start:-  function to create , read and erase Cookie
         */

    createCookie(name, value, days) {
        let expires;
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        else {
            expires = '';
        }
        document.cookie = name + '=' + value + expires + '; path=/';
    }

    readCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    eraseCookie(name) {
        this.createCookie(name, '', -1);
    }

    public stop() {
        this.player && this.player.stop();
    }

    public alreadyLoaded() {
        return !!this.player;
    }

    public supported(video: Video) {
        return video.type === 'video' ||
            video.type === 'stream' ||
            (video.type === 'embed' && this.embedSupportedByPlyr(video.url));
    }

    private buildSource(video: Video) {
        if (video.type === 'embed') {
            return {
                type: 'video',
                poster: video.thumbnail,
                sources: [{
                    src: video.url,
                    provider: this.isYoutube(video.url) ? 'youtube' : 'vimeo',
                }],
            };
        } else {
            const tracks = (video.captions || []).map((caption, i) => {
                return {
                    kind: 'captions',
                    label: caption.name,
                    srclang: caption.language,
                    src: caption.url ? caption.url : this.settings.getBaseUrl() + 'secure/caption/' + caption.id,
                    default: i === 0,
                };
            });
            return {
                type: 'video',
                captions: {active: false},
                title: video.name,
                sources: [
                    {src: video.url},
                ],
                poster: video.thumbnail,
                tracks,
            };
        }
    }

    private isYoutube(url: string): boolean {
        return url.includes('youtube.com');
    }

    private isVimeo(url: string): boolean {
        return url.includes('vimeo.com');
    }

    private embedSupportedByPlyr(url: string): boolean {
        return this.isYoutube(url) || this.isVimeo(url);
    }

    public destroy() {
        this.onPlayerStateChange(this.player.currentTime);
        this.player && this.player.destroy();
        this.player = null;
    }
}
