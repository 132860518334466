<div class="row m-0 p-0">
    <div class="header m-0 p-0" [style.background-image]="'url('+ data['item']['backdrop'] +')'">
        <button class="btn btn-sm close-btn" matTooltip="close" (click)="onNoClick()"><i class="material-icons">cancel</i></button>
  <div class="slide-cover">
      <div class="slide-cover1">
        <div class="slide-content">    <a class="title" [routerLink]="urls.mediaItem(data['item'])" draggable="false">{{data['item']["name"]}}</a>
<div class="d-flex flex-row">
    <button class="btn btn-lg mx-1 btn-light"  (click)="playVideo(data['item'])" >
        <!-- <mat-icon svgIcon="play-arrow"></mat-icon> -->
        <i class="material-icons">play_arrow</i>
        <span trans>Watch Now</span>
     
    </button>
    <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="addToWatchlist()" [class.hidden]="itemInWatchlist$ | async" [disabled]="loading$ | async">add</button>
    <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="removeFromWatchlist()" [class.hidden]="!(itemInWatchlist$ | async)" [disabled]="loading$ | async">close</button>
    <rating-widget [item]="data['item']" [showRateButton]="isReleased(data['item']['release_date'])"></rating-widget>
</div>
            <!-- <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark">thumb_up</button>
            <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark">thumb_down</button> -->
        </div>
      </div>
</div>   
</div>
    <div class="info pb-3  ">
        <div class="row p-5 pt-0">
            <div class="col-md-12 col-sm-12 col-12">
                <div class="d-flex p-0 mx-0 flex-row container">
                    <span class="mx-2 ">{{data['item']['year']}}</span>
                    <span class=" border px-3 rounded mx-2 " *ngIf="data['item']['adult']">18+</span>
                    <span class=" border px-3 rounded mx-2 " *ngIf="!data['item']['adult']">16+</span>
                    <span class="mx-2 ">{{data['item']["runtime"]}}m</span>
                    <span class="border border-secondary text-muted px-3 font-weight-bold rounded mx-2 ">HD</span>
                </div>
                <div class="row mt-3 ">
                    <p class="fs-3 text-break">{{data['item']['tagline']}}</p>
                </div>
            </div>



<!-- 
            <div class="col-md-4 col-sm-6 col-12">
                <p class="fs-3 text-break" >
                    <span class="text-muted">
                        Cast:
                    </span>
                    <span><a href="#">   Kritika Sachdeva
                  
                    </a></span>
                    <span><a href="#">
                        Sasho
                    </a></span>
                    <span><a href="#">
                        Sanghmitra Hitaishi
                    </a></span>
                    <span><a href="#">
                       more
                    </a></span>
                </p>

                <p class="fs-3 mt-1 text-break" >
                    <span class="text-muted">
                        Genres : 
                    </span>
                    
                    <span *ngFor="let genre of data['item']['genres']"><a href="#">
                        {{genre.display_name || genre.name}}
                    </a></span>
                    
      </p> 
      <p class="fs-3 mt-1 text-break" >
        <span class="text-muted">
            This movie is:
        </span>
        
        <span ><a href="#">
           Dark
        </a></span>
        
</p> 
               </div> -->
               <div *ngIf="data['item']['episode_count']>0" class="col-12 p-0 mx-0 my-3">
                   <div class="d-flex justify-content-between align-items-center flex-row">
                       <span class="font-weight-bold fs-5 ">Episodes</span>
                       <mat-form-field appearance="fill">
                        <mat-label>Season1</mat-label>
                        <mat-select >
                          <mat-option value="option1">Season1</mat-option>
                          <mat-option value="option2">Season2</mat-option>
                          <mat-option value="option3">Season3</mat-option>
                        </mat-select>
                      </mat-form-field>
                   </div>
                   <div class=" col-12 p-10"  >
                    <ul class="list-group subtitle">
                        <li class="subtitle-item">
                          <div class="row">
                              <div class="col-1 d-flex justify-content-center flex-row align-items-center">
                                  <h1>1</h1>
                              </div>
                            <div class="col-11">
                                <div class="row">
                                    <div class="col-2 ">  <div class="image-parent">
                                        <img src="/client/assets/images/landing/endgame.jpg?v2" class="img-fluid" alt="quixote">
                                    </div>
                                 
                               </div>
                               <div class="col-10">
                                   <div class="row">
                                       <div class="d-flex justify-content-between">
                                           <span class="fs-5">
                                               Episode 1
                                           </span>
                                           <span class="fs-5">
                                            55m
                                        </span>
                                        
                                       
                                    
                                    
                                    </div> 
                                    <div class="col-12">
                                        <span class="fs-4">
                                            When a professor gives Natalia some troubling news about the app she’s building for a tech competition, she's forced to pivot to a new idea.
                                        </span>
                                        
                                    </div>
                                </div>
                               </div>
                                </div>
                            </div>  
                            </div>
                        </li>
                        <li class="subtitle-item">
                            <div class="row">
                                <div class="col-1 d-flex justify-content-center flex-row align-items-center">
                                    <h1>2</h1>
                                </div>
                              <div class="col-11">
                                  <div class="row">
                                      <div class="col-2 ">  <div class="image-parent">
                                          <img src="/client/assets/images/landing/endgame.jpg?v2" class="img-fluid" alt="quixote">
                                      </div>
                                   
                                 </div>
                                 <div class="col-10">
                                     <div class="row">
                                         <div class="d-flex justify-content-between">
                                             <span class="fs-5">
                                                 Episode 1
                                             </span>
                                             <span class="fs-5">
                                              55m
                                          </span>
                                          
                                         
                                      
                                      
                                      </div> 
                                      <div class="col-12">
                                          <span class="fs-4">
                                              When a professor gives Natalia some troubling news about the app she’s building for a tech competition, she's forced to pivot to a new idea.
                                          </span>
                                          
                                      </div>
                                  </div>
                                 </div>
                                  </div>
                              </div>  
                              </div>
                          </li>
                      </ul>
                    </div>
               </div>
               <div class="col-12 m-0 p-0 " *ngIf="data['list'].length>0">
                   <div class="row">
                       <h2 class="font-weight-bold"> More Like This</h2>
                       <div class="col-md-4 col-sm-6 col-12 mt-3"  [routerLink]="urls.mediaItem(item)" *ngFor="let item of data['list'];let i=index" >
          
                        <div class="card" *ngIf="i<counter" >
                            <div class="card-header p-0 m-0">
                    <div class="card-img-top card-img" [style.background-image]="'url('+item['backdrop']+')'" >
                        <span class="position-absolute text-white" style="    top: 5px;
                        right: 13px;">{{item["runtime"]}}m</span>
                                  <div class="position-absolute d-flex align-items-center play-button  my-auto text-center justify-content-center" style="top:0;left:0;bottom:0;right:0;">
                                    <button class="rounded-circle mx-2 text-center  btn material-icons fs-5 border border-secondary text-white btn-dark" (click)="playVideo(item)">play_arrow</button>
              
                                </div>
                    </div>

                            
                            </div>
                                     <div class="card-body">
                                <div class="row">
                                    <!-- <span *ngIf="item.match" class="text-success font-weight-bold d-block">
                                        {{item.match}}
                                    </span> -->
                                    <div class="d-flex p-0 mx-0 flex-row align-items-center container">
                                      
                                    <span class=" border px-3 rounded mx-2 " *ngIf="item['adult']">18+</span>
                                    <span class=" border px-3 rounded mx-2 " *ngIf="!item['adult']">16+</span>
                                    <span class="mx-2 ">{{item["year"]}}</span>
                                    <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="AddToWatchlist(item)"  [class.hidden]="item.watchList" [disabled]="loading$ | async">add</button>
                                    <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="RemoveFromWatchlist(item)"  [class.hidden]="!item.watchList" [disabled]="loading$ | async">close</button>
                                               </div>
                                </div>
                              <p class="card-text mt-2">{{item["tagline"]}}</p>
                            </div>
                          </div>
                        </div>
                  
                    <div class="row border-top position-relative" *ngIf="counter>=6">
                        <div class="position-absolute d-flex align-items-center my-auto text-center justify-content-center" style="top:0;left:0;bottom:0;right:0;">
                            <button class="rounded-circle mx-2 text-center  btn material-icons fs-5 border border-secondary text-white btn-dark" (click)="toggleExpansion()">{{counter==data['list'].length?'expand_less':'expand_more'}}</button>
      
                        </div>
                    </div>
                   </div>
               </div>
               <!-- <div class="col-md-12 col-sm-12 mt-5 col-12">
                <p class="fs-3 text-break" >
                    <span class="fs-1">
                        About <span class="font-weight-bold">{{data['item']["name"]}}</span>
                        </span>
                        </p>
                <p class="fs-3 text-break" >
                    <span class="text-muted">
                        Director:
                    </span>
                    <span><a href="#">   Shadab Khan
                  
                    </a></span>
                    
                </p>

                <p class="fs-3 text-break" >
                    <span class="text-muted">
                        Cast:
                    </span>
                    <span><a href="#">   Kritika Sachdeva
                  
                    </a></span>
                    <span><a href="#">
                        Sasho
                    </a></span>
                    <span><a href="#">
                        Sanghmitra Hitaishi
                    </a></span>
                    <span><a href="#">
                       more
                    </a></span>
                </p>
                <p class="fs-3 text-break" >
                    <span class="text-muted">
                        Writer:
                    </span>
                    <span><a href="#">   Shadab Khan
                  
                    </a></span>
                    
                </p>
                <p class="fs-3 mt-1 text-break" >
                    <span class="text-muted">
                        Genres : 
                    </span>
                    
                    <span *ngFor="let genre of data['item']['genres']"><a href="#">
                        {{genre.display_name || genre.name}}
                    </a></span>
                    
      </p> 
      <p class="fs-3 mt-1 text-break" >
        <span class="text-muted">
            This movie is:
        </span>
        
        <span ><a href="#">
           Dark
        </a></span>
        
</p> 
<p class="fs-3 mt-1 text-break" >
    <span class="text-muted">
       Maturity rating:
    </span>
    
    <span class=" border px-3 rounded mx-2 " *ngIf="data['item']['adult']">18+</span>
    <span class=" border px-3 rounded mx-2 " *ngIf="!data['item']['adult']">16+</span>
    <span>
      
            substances, sucide
        
    </span>
    <span>
       
        Recommended for ages <span class="  px-3 rounded mx-2 " *ngIf="data['item']['adult']">18 and up.</span>
        <span class="  px-3 rounded mx-2 " *ngIf="!data['item']['adult']">16 and up.</span>
    
    </span>
    
</p> 
               </div> -->
        </div>
    </div>
</div>