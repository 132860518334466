<ng-container *ngIf="!MainMenu && ((currentUser.isLoggedIn$ | async) || (breakpoints.isMobile$ | async)); else authButtons">
  
    <div class="d-flex flex-row">
        <search-input class="nav-searchbar col"></search-input>
        <!-- <div class="menu-trigger"  *ngIf="!hideRegisterButton">
            <div class="menu-trigger" [matMenuTriggerFor]="notification"  #ntrigger="matMenuTrigger"   >
            <mat-icon class="mr-2"   >notifications</mat-icon>
        </div>
            <mat-menu #notification="matMenu" class="logged-in-user-widget-menu menu">
              
                <div class=" col-12 p-10"  (mouseleave)="ntrigger.closeMenu()" >
                    <ul class="list-group subtitle">
                        <li class="subtitle-item d-flex justify-content-around align-items-center">
                            <div class="image-parent">
                                <img src="/client/assets/images/landing/endgame.jpg?v2" class="img-fluid" alt="quixote">
                            </div>
                          Don Quixote 
                       
                        </li>
                        <li class="subtitle-item d-flex justify-content-around align-items-center">
                            <div class="image-parent">
                                <img src="/client/assets/images/landing/wick.jpg?v2" class="img-fluid" alt="lay">
                                </div>
                          As I Lay Dying 
                       
                        </li>
                        <li class="subtitle-item d-flex justify-content-around align-items-center"> 
                            <div class="image-parent">
                                <img src="/storage/homepage/YipodvuKk9u4C60PL21tejxCjxY7vfRfq1gw8tvf.png?v2" class="img-fluid" alt="things">
                                </div> 
                          Things Fall Apart
                         
                        </li>
                      </ul>
                    </div>
                    </mat-menu>

        </div> -->
        <div class="menu-trigger" [matMenuTriggerFor]="menu"  #mtrigger="matMenuTrigger"   *ngIf="!hideRegisterButton">
           <div class="menu-trigger" >
            <img class="avatar" [src]="currentUser.get('avatar')" >
     <!-- <search-input class="nav-searchbar"></search-input> -->
            <!-- <div class="email">{{currentUser.get('email')}}</div> -->
           
            <mat-icon svgIcon="arrow-drop-down" class="dropdown-icon" *ngIf="!mtrigger.menuOpen"></mat-icon>
            <mat-icon  class="dropdown-icon" *ngIf="mtrigger.menuOpen">arrow_drop_up</mat-icon>
    <!-- 
            <button class="no-style mobile-toggle" aria-label="Show logged in user options">
                <img class="avatar" [src]="currentUser.get('avatar')">
            </button> -->
        </div>
        <mat-menu #menu="matMenu" [overlapTrigger]="false"  class="logged-in-user-widget-menu">
          
            <logged-in-user-menu   ></logged-in-user-menu>
        </mat-menu>
        </div>
        
        <div class="menu-trigger"  [matMenuTriggerFor]="Mymenu" #trigger="matMenuTrigger"    *ngIf="!hideRegisterButton">
            <div class="menu-trigger" >
            <mat-icon class="mx-2" > tune</mat-icon>
            <mat-icon svgIcon="arrow-drop-down" class="dropdown-icon" *ngIf="!trigger.menuOpen"></mat-icon>
            <mat-icon  class="dropdown-icon" *ngIf="trigger.menuOpen">arrow_drop_up</mat-icon>
            <!-- <button class="no-style mobile-toggle" aria-label="Show logged in user options">
                <mat-icon svgIcon="person"></mat-icon>
            </button> -->
         
        </div>
        <mat-menu #Mymenu="matMenu"    class="logged-in-user-widget-menu menu ">
          <div >
            <div class=" col-12 p-10 subtitle" >
                <div class="d-flex flex-row py-3   ">
              
                    <span class="fs-5 subtitle-item" >       <i class="material-icons"> smart_display &nbsp;</i>&nbsp; Previews
                     
                    </span>
                    <mat-slide-toggle
                 
                        class="pull-right ml-auto flex-shrink-1"
                        [checked]="true"
                        [disabled]="false"
                        (click)="$event.stopPropagation();">
                 
                    </mat-slide-toggle>
                  
                </div>
                <div class="row ">
                   <div class="col-12 fs-3   subtitle-item ">
                       <p> Previews will play while browsing</p>
                   </div>
                </div>


                <div class="d-flex flex-row border-top   ">
              
                    <span class="fs-5  subtitle-item  ">       <i class=" material-icons"> volume_up
                        &nbsp;</i>&nbsp; Sound
                     
                    </span>
                    <mat-slide-toggle
                 
                        class="pull-right ml-auto flex-shrink-1"
                        [checked]="sound"
                        [disabled]="false"
                        (change)="SoundCheck($event)"
                        (click)="$event.stopPropagation();">
                 
                    </mat-slide-toggle>
                  
                </div>
                <div class="row">
                   <div class="col-12 fs-3  subtitle-item ">
                       <p> Sound is {{sound?'on':'off'}} while browsing</p>
                   </div>
                </div>
              </div>
              <div class="col-12 bg-primary p-10">
                <p class=" text-white fs-5">New! More browse controls</p>
              </div>
          </div>
        </mat-menu>
    </div>
     
    </div>

</ng-container>

<ng-template #authButtons>
    <a *ngIf="!config.get('registration.disable') && !hideRegisterButton" class="register-btn" routerLink="/register"  mat-button trans>Register</a>
    <a routerLink="/login" class="login-btn btn  btn-success btn-lg m-4" trans>Login</a>
</ng-template>
