import { filter } from 'rxjs/operators';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { PlayerOverlayService } from '../../player/player-overlay.service';
import { BehaviorSubject } from 'rxjs';
import { UserLibraryService } from '../../user-library.service';

@Component({
    // selector: 'dialog',
    templateUrl: './Description.component.html',
    styleUrls: ['./Description.component.scss'],
  })
  export class DescriptionModalComponent  {
    public counter:number = 6;
    public loading$ = new BehaviorSubject(false);
    public itemInWatchlist$ = new BehaviorSubject(false);
    constructor(
      public dialogRef: MatDialogRef<DescriptionModalComponent>,
      private playerOverlay: PlayerOverlayService,

      @Inject(MAT_DIALOG_DATA) public data: { value: any },
      public urls: TitleUrlsService,
      public userLibrary: UserLibraryService
      ) {

      }
      onNoClick(): void {
        this.dialogRef.close();
      }
  
  }