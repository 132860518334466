<ng-content></ng-content>

<ng-container *ngIf="list">
 
    <!-- (swipeleft)="changeSlide('previous')" (swiperight)="changeSlide('next')" -->
    <div class="slider-track" #sliderTrack >
        <!-- *ngFor="let item of filterTitles(list.items)[0]"  -->
        <div class="slide" [style.background-image]="'url('+ filterTitles(list.items)[0].backdrop +')'">
            <div class="slide-cover">
                <div class="slide-cover1">
                    <div class="slide-content container">
                        <!-- <div class="rating" *ngIf="filterTitles(list.items)[0].rating">
                            <mat-icon svgIcon="star"></mat-icon>
                            {{filterTitles(list.items)[0].rating}} / 10
                        </div> -->
                        <a class="title" [routerLink]="urls.mediaItem(filterTitles(list.items)[0])" draggable="false">{{filterTitles(list.items)[0].name}}</a>
                        <p class="description text-truncate cursor-pointer"   (click)="showPopup(filterTitles(list.items)[0].description)"  >{{filterTitles(list.items)[0].description}}</p>
                        <!-- <genre-widget [genres]="filterTitles(list.items)[0].genres"></genre-widget> -->
                        <!-- *ngIf="filterTitles(list.items)[0]?.videos?.length" -->
                        <button class="btn btn-lg mx-1 btn-light" (click)="playVideo(filterTitles(list.items)[0])" >
                            <!-- <mat-icon svgIcon="play-arrow"></mat-icon> -->
                            <i class="material-icons">play_arrow</i>
                            <!-- <span *ngIf="filterTitles(list.items)[0].videos[0].category !== 'full'; else playFull" trans>Play</span> -->
                               <span  trans>Play</span>
                            <!-- <ng-template #playFull>
                                <span trans>Watch Now</span>
                            </ng-template> -->
                        </button>
                        <button (click)="OpenDialog(filterTitles(list.items)[0],list.items)" class="btn btn-dark btn-lg mx-1"><i class="material-icons">info</i> More Info</button>
                        <button  class=" position-absolute rounded-circle mx-2  btn material-icons fs-5 border border-secondary text-white btn-dark" style="background: rgba(0,0,0,0.5) !important;right: 100px;"> volume_down</button>
                   
                        <button  class="btn btn-dark btn-lg mx-1 px-5 position-absolute" style="border-left: 3px solid;background: rgba(0,0,0,0.5) !important;right: -3px;" *ngIf="filterTitles(list.items)[0]['adult']"> 18+</button>
                        <button  class="btn btn-dark btn-lg mx-1 px-5 position-absolute" style="border-left: 3px solid;background: rgba(0,0,0,0.5) !important;right: -3px;" *ngIf="!filterTitles(list.items)[0]['adult']"> 16+</button>
              
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="slider-controls">
        <button class="control" *ngFor="let item of list.items; index as index;" [class.active]="index === (activeSlide$ | async)" (tap)="changeSlide(index)">
            <span class="control-inner"></span>
        </button>
    </div> -->
</ng-container>

