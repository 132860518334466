<!-- <p-carousel [value]="items" [numVisible]="7" [numScroll]="7" [responsiveOptions]="responsiveOptions" [circular]="true"> -->
    <!-- [src]="mode === 'portrait' ? item.poster -->
    <!-- <ng-template let-item pTemplate="item"> -->
        <div *ngIf="grid" class="row">
     <div class=" col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"  *ngFor="let item of items; trackBy: trackByFn" >
            <figure class="item grid-item "   [title]="item.name" >
                <media-image [mode]="mode" [src]="item.backdrop" size="medium" [alt]="item.name + ' image'" [link]="urls.mediaItem(item)">
                    <!-- <button mat-mini-fab (click)="playVideo(item)" class="play-button play-button-animation" *ngIf="showPlayButton && item.type === 'title' && item?.videos?.length">
                        <mat-icon svgIcon="play-arrow"></mat-icon>
                    </button> -->
                </media-image>
                <figcaption >
                    <!-- <a class="title" [routerLink]="urls.mediaItem(item)">{{item.name}}</a>
                    <div class="subtitle">
                        <span *ngIf="mode === 'landscape' && item.certification" class="subtitle-item certification">{{item.certification}}</span>
                        <genre-widget *ngIf="item.genres?.length" class="subtitle-item" [genres]="item.genres" [limit]="2" [separator]="mode === 'landscape' ? ', ' : '/'"></genre-widget>
                        <span *ngIf="mode === 'landscape' && item.year" class="subtitle-item last">{{(item.is_series ? 'Series' : 'Movie') | trans}} ({{item.year}})</span>
                    </div> -->
                    <div class="row mt-3 ">
                        <div class="col-10 d-flex flex-row justify-content-start align-items-center">
                            <button class="rounded-circle mx-2  btn material-icons fs-5 border border-secondary text-dark btn-white" (click)="playVideo(item)">play_arrow</button>
                            <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="addToWatchlist(item)"  [class.hidden]="item.watchList" [disabled]="loading$ | async">add</button>
                            <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="removeFromWatchlist(item)"  [class.hidden]="!item.watchList" [disabled]="loading$ | async">close</button>
                            <rating-widget [item]="item" [showRateButton]="isReleased(item.release_date)"></rating-widget>
                        </div>
                        <div class="col-2 d-flex flex-row justify-content-start align-items-center">
                            <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  matTooltip="Episodes & info"  (click)="OpenDialog(item,items)">expand_more</button>
                
                        </div>
                        <div class="col-12 mt-3">
                            <div class="d-flex p-0 mx-0 flex-row container">
                                <span class="mx-2 ">2017</span>
                                <span class=" border px-3 rounded mx-2 ">16+</span>
                                <span class="mx-2 ">2h 5m</span>
                                <span class="border border-secondary text-muted px-3 font-weight-bold rounded mx-2 ">HD</span>
                            </div>
                        </div>
                    </div>
                </figcaption>
                <streamable-label [item]="item"></streamable-label>
            </figure>
        </div>
        </div>
        <!-- <div class="w-100 py-5 h-100" style="overflow-x:hidden ;"> -->
        <owl-carousel-o [options]="customOptions" *ngIf="!grid"  id="myCarousel" (initialized)="getData($event)">
            <ng-template *ngFor="let item of items; trackBy: trackByFn;let i=index" carouselSlide  >
      
        <figure class="item"   [title]="item.name"  >
            <media-image [mode]="mode" [src]="item.backdrop" size="medium" [alt]="item.name + ' image'" [link]="urls.mediaItem(item)">
                <!-- <button mat-mini-fab (click)="playVideo(item)" class="play-button play-button-animation" *ngIf="showPlayButton && item.type === 'title' && item?.videos?.length">
                    <mat-icon svgIcon="play-arrow"></mat-icon>
                </button> -->
            </media-image>
            <figcaption >
                <!-- <a class="title" [routerLink]="urls.mediaItem(item)">{{item.name}}</a>
                <div class="subtitle">
                    <span *ngIf="mode === 'landscape' && item.certification" class="subtitle-item certification">{{item.certification}}</span>
                    <genre-widget *ngIf="item.genres?.length" class="subtitle-item" [genres]="item.genres" [limit]="2" [separator]="mode === 'landscape' ? ', ' : '/'"></genre-widget>
                    <span *ngIf="mode === 'landscape' && item.year" class="subtitle-item last">{{(item.is_series ? 'Series' : 'Movie') | trans}} ({{item.year}})</span>
                </div> -->
                <div class="row mt-3 ">
                    <div class="col-10 d-flex flex-row justify-content-start align-items-center">
                        <button class="rounded-circle mx-2  btn material-icons fs-5 border border-secondary text-dark btn-white" (click)="playVideo(item)">play_arrow</button>
                        <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="addToWatchlist(item)"  [class.hidden]="item.watchList" [disabled]="loading$ | async">add</button>
                        <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  (authClick)="removeFromWatchlist(item)"  [class.hidden]="!item.watchList" [disabled]="loading$ | async">close</button>
                        <rating-widget [item]="item" [showRateButton]="isReleased(item.release_date)"></rating-widget>
                    </div>
                    <div class="col-2 d-flex flex-row justify-content-start align-items-center">
                        <button class="rounded-circle mx-2 opacity-4 btn material-icons fs-5 border border-secondary text-white btn-dark"  matTooltip="Episodes & info"  (click)="OpenDialog(item,items)">expand_more</button>
            
                    </div>
                    <div class="col-12 mt-3">
                        <div class="d-flex p-0 mx-0 flex-row container">
                            <span class="mx-2 ">2017</span>
                            <span class=" border px-3 rounded mx-2 ">16+</span>
                            <span class="mx-2 ">2h 5m</span>
                            <span class="border border-secondary text-muted px-3 font-weight-bold rounded mx-2 ">HD</span>
                        </div>
                    </div>
                </div>
            </figcaption>
            <streamable-label [item]="item"></streamable-label>
        </figure>
    </ng-template>
        </owl-carousel-o>
    <!-- </div> -->
    <!-- </ng-template>
</p-carousel> -->


