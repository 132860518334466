<div class="action-bar">
    <button mat-raised-button type="button" color="accent" (authClick)="openCrupdateReviewModal()" trans>Write a Review</button>
</div>

<div class="reviews-list" *ngIf="!alreadyLoadedReviewsForThisTitle || reviews.size; else noReviews">
    <div class="review" *ngFor="let review of reviews.values()">
        <div class="header">
            <div class="score" [ngClass]="getScoreColor(review.score)">{{review.score}}</div>
            <div class="meta">
                <div class="author">
                    {{review.author || review.user?.display_name}}
                </div>
                <div class="date">{{review.created_at | formattedDate}}</div>
            </div>
            <div class="actions">
                <button mat-icon-button color="danger" (click)="maybeDeleteReview(review)" *ngIf="currentUser.get('id') === review.user_id">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
                <a [href]="review.link" target="_blank" mat-icon-button *ngIf="review.link">
                    <mat-icon svgIcon="open-in-new"></mat-icon>
                </a>
            </div>
        </div>
        <div class="body" moreLessText="500" [textContent]="review.body"></div>
    </div>
</div>

<ng-template #noReviews>
    <no-results-message imgSrc="client/assets/images/illustrations/like-dislike.svg">
        <span primary-text trans>There are no reviews for this title yet.</span>
        <span secondary-text trans>Be the first to leave one!</span>
    </no-results-message>
</ng-template>

<loading-indicator [isVisible]="loading$ | async" class="overlay overlay-light overlay-partial"></loading-indicator>
